import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { AppLanguage } from "@const/app-languages";
import { Input } from "reactstrap";
import { useSelector } from "react-redux";

export const LanguageSwitcher = ({ register }) => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const { locale } = useIntl();

  const { account } = useSelector((state) => state.profile.account);

  const changeHandler = async (event) => {
    if (!account.languageCode || account.accountStatus === "EMAIL_CONFIRMED") {
      const npn = pathname.replace(/[^][-a-zA-Z]*/, event.target.value);
      history.push("/" + npn + search);
    }
  };

  useEffect(() => {
    if (account.languageCode) {
      const npn = pathname.replace(
        /[^/][-a-zA-Z]*/,
        account.languageCode.toLowerCase()
      );
      history.push(npn + search);
    } else {
      const npn = pathname.replace(/[^/][-a-zA-Z]*/, locale);
      history.push(npn + search);
    }
  }, [account.languageCode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Input
      type={"select"}
      id={"language-switcher"}
      className={"language-switcher"}
      name={"languageSwitcher"}
      defaultValue={
        account.languageCode
          ? account.languageCode.toLowerCase()
          : AppLanguage[locale]
      }
      onChange={changeHandler}
      innerRef={register}
    >
      {Object.keys(AppLanguage).map((lang) => (
        <option key={lang} value={lang}>
          {AppLanguage[lang]}
        </option>
      ))}
    </Input>
  );
};
